import React from "react";
import { Stack, Main, Sidebar } from "@layout";
import PageTitle from "@components/PageTitle";
import Divider from "@components/Divider";
import Seo from "@widgets/Seo";
import ContactForm from "@widgets/ContactForm";
import ContactInfo from "@widgets/ContactInfo";
import Commitment from "@widgets/Commitment";

export default () => (
  <>
    <Seo title="Contact" />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Let's Connect"
          subheader="Have any coding questions? Feel free to drop us a line below!"
        />
        <Divider />
        <ContactForm />
      </Main>
      <Sidebar>
        <Commitment />
        <Divider />
        <ContactInfo />
      </Sidebar>
    </Stack>
  </>
);
